<template>
  <div>
    <div>
    <van-grid :columnNum="1" size="16px">
      <van-grid-item icon="user-o">
        <div slot="text">
          <div>{{ phone }}</div>
          <div>{{ level_name }}</div>
          <div>专属优化地址：{{ coupon_code }}.ussss.cn</div>
        </div>
      </van-grid-item>
    </van-grid>
  </div>

    <div>
      <van-cell-group>
        <van-cell icon="orders-o" to="/income/list" title="收入" is-link />
        <van-cell icon="gold-coin-o" to="/withdraw/index" title="提现" is-link />
        <van-cell icon="comment-o" to="/promotion/rule" title="奖励规则" is-link />
        <van-cell icon="question-o" to="/promotion/howto" title="如何推广" is-link />
        <template v-if="level === 2">
        <van-cell icon="share-o" to="/member/invitation_code" title="注册邀请码" is-link />
        <van-cell icon="gem-o" to="/member/gold_partner" title="升级成金牌合伙人" is-link />
        </template>
        <template v-if="level === 0">
        <van-cell icon="gem-o" to="/member/gold_partner" title="升级成金牌合伙人" is-link />
        </template>
        <van-cell icon="ecard-pay" to="/member/bank_info" title="设置收款账户" is-link />
        <van-cell icon="user-circle-o" to="/member/profile" title="个人信息" is-link />
        <van-cell icon="setting-o" to="/member/edit_password" title="修改密码" is-link />
        <van-cell icon="close" @click="quit" title="退出登录" is-link />
      </van-cell-group>
    </div>
    <CopyRight></CopyRight> 
  </div>
</template>

<script>
import CopyRight from "@/components/CopyRight.vue";
import axios from "axios";
export default {
  data() {
    return {
      phone: "",
      level: 0,
      level_name: "",
      coupon_code: "",
      gold: "0",
      };
  },
  components: {
    CopyRight,
  },
  mounted: function () {
    this.loadData();
  },

  methods: {
    loadData() {
      let token = window.sessionStorage.getItem("token");
      axios
        .get("api/member/get_info?token=" + token)
        .then((res) => {
          if (res.data.code == 0) {
            var reg=/^(\d{3})\d{4}(\d{4})$/;
            this.phone = res.data.data.phone.replace(reg, "$1****$2");
            this.coupon_code = res.data.data.coupon_code;
            this.level = res.data.data.level;
            if(this.level == 0){
              this.level_name = "普通合伙人";
            }else if(this.level == 1){
              this.level_name = "金牌合伙人";
            }else{
              this.level_name = "普通合伙人";
            }
          } else {
            this.$toast.fail(res.data.message);
          }
        })
        .catch((err) => {
          console.log("profile err-->" + err);
        });
    },
    goLogin() {
      this.$router.push("/login");
    },

    quit() {
      let token = window.sessionStorage.getItem("token");
      if (token == null || token == "") {
        return this.$toast.fail("您还未登录");
      }
      this.$dialog.confirm({
        title: "提示",
        message: "确定退出登录?",
        beforeClose: this.beforeClose
      }).catch(()=>{});
    },

    beforeClose(action, done) {
      if (action === "confirm") {
        let token = window.sessionStorage.getItem("token");
        axios.get("api/member/logout?token="+token).then(res => {
          if (res.data.code == 0) {
            this.$toast.success("退出成功");
            window.sessionStorage.setItem("token", "");
            this.$router.push({path: '/member/login'});
          } else {
            this.$toast.fail("退出失败");
          }
          done();
        });
      } else {
        done();
      }
    },
  },
};
</script>